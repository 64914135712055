import React from 'react';
import AboutUs from '../components/sections/AboutUs';

const About = () => {

    return (
        <>
            <AboutUs />
        </>
    );
}

export default About;