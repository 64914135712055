import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
// import Input from '../elements/Input';
// import Button from '../elements/Button';
// import ButtonGroup from '../elements/ButtonGroup';

const propTypes = {
    children: PropTypes.node,
    ...SectionProps.types
}

const defaultProps = {
    children: null,
    ...SectionProps.defaults
}

const AboutUs = ({
    className,
    children,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const outerClasses = classNames(
        'section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            <span className="text-color-primary">About Us</span>
                        </h1>
                    </div>
                    <div className="reveal-from-bottom container-sm" data-reveal-delay="600">
                        <p>At GabasTech we believe small businesses are fundamental to our economy. Small business in USA are responsible for more than 50% of jobs. We want to help with affordable and robust IT solutions so they can focus on what makes them shine. We are passionate about technology, and how it can benefit businesses growth.</p>
                    </div>
                    {/* <form>
                        <Input id="email" type="email" label="Email" labelHidden hasIcon="right" placeholder="Your best email">
                        </Input>
                        <Input id="question" type="textarea" label="Question" labelHidden hasIcon="right" placeholder="How can we help you?">
                        </Input>
                        <br />
                        <Button color="primary" wideMobile>Submit Inquiry</Button>
                    </form> */}
                </div>
            </div>
        </section>
    );
}

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;